import React, { Fragment, useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import { appConfig } from "../appConfig";

const Home = (props) => {
  const [projects, setProjects] = useState({});
  const [message, setMessage] = useState("Loading projects.. Please wait!");

  useEffect(() => {
    axios
      .get(`${appConfig.apiRootUrl}/project-plan/projects`)
      .then((res) => {
        try {
          if (Object.keys(res.data.data.projects).length > 0) {
            setProjects(res.data.data.projects);
          } else {
            setMessage("All done! No projects at present");
          }
        } catch (error) {
          setMessage("Project(s) could not be loaded!");
        }
      })
      .catch((error) => {
        console.log("error: ", error);
        setMessage("Project(s) could not be loaded!");
      });
  }, []);

  return (
    <Fragment>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-12 m-4 p-4 text-center">
            <h1>Outstanding Projects</h1>
          </div>
          {projects.length > 0 ? (
            <Fragment>
              {projects.map((project) => (
                <Link
                  key={project.id}
                  to={`/${project.unique_title}`}
                  className="col-lg-4 col-md-4 col-sm-4 col-xs-12 shadow m-4 p-4 btn btn-light"
                >
                  <div className="box-part text-center">
                    <div className="title">
                      <h4>{project.title}</h4>
                    </div>

                    <div className="text">
                      <span>
                      {project.description}
                      </span>
                    </div>
                  </div>
                </Link>
              ))}
            </Fragment>
          ) : (
            <h4>{message}</h4>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default withRouter(Home);
