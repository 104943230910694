import React, { Fragment, useEffect, useState} from "react";
import { MileStone } from "./Milestone";

export const MileStones = (props) => {

  const [milestones, setMilestones] = useState([]);

  useEffect(() => {
    setMilestones(props.milestones);
  }, [props.milestones]);

  return (
    <Fragment>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="main-timeline9">{milestones.map(milestone => (
              <MileStone key={milestone.id} milestone={milestone} users={props.users} />
            ))}</div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
