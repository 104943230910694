import React, { Fragment } from "react";

const MilestoneForm = (props) => {
  return (
    <Fragment>
      <div className="row">
        <div className="col-sm-12">
          <div className="form-group">
            <label htmlFor="status_code">Status</label>

            <select
              className="form-control"
              required="required"
              id="status_code"
              name="status_code"
              onChange={props.handleChange}
              defaultValue={props.milestone.status_code}
            >
              <option value="">-- Select --</option>
              <option value="NS">Not Started</option>
              <option value="IP">In Progress</option>
              <option value="CO">Completed</option>
            </select>

            <span className="help-block">
              <strong></strong>
            </span>
          </div>
          <div className="form-group">
            <label htmlFor="message" className="">
              Message (optional)
            </label>

            <textarea
              className="form-control"
              name="message"
              cols="50"
              rows="10"
              id="message"
              onChange={props.handleChange}
            ></textarea>

            <span className="help-block">
              <strong></strong>
            </span>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="user_id" className="">
                  User
                </label>

                <select
                  className="form-control"
                  required="required"
                  id="user_id"
                  name="user_id"
                  onChange={props.handleChange}
                >
                  <option value="">-- Select --</option>
                  {props.users.map((user) => (
                    <option key={user.id} value={user.id}>
                      {user.name}
                    </option>
                  ))}
                </select>

                <span className="help-block">
                  <strong></strong>
                </span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="password" className="">
                  Password
                </label>

                <input
                  className="form-control"
                  name="password"
                  type="password"
                  id="password"
                  required="required"
                  onChange={props.handleChange}
                />

                <span className="help-block">
                  <strong></strong>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default MilestoneForm;
