import React, { Fragment, useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import { MileStones } from "./Milestones";
import { appConfig } from "../appConfig";

const Project = (props) => {
  const [project, setProject] = useState({});
  const [message, setMessage] = useState("Loading project plan.. Please wait!");

  const unique_title = props.match.params.unique_title;
  useEffect(() => {
    axios
      .get(`${appConfig.apiRootUrl}/project-plan/project/${unique_title}`)
      .then((res) => {
        try {
          if (Object.keys(res.data.data.project).length > 0) {
            setProject(res.data.data.project);
          } else {
            setMessage("Could not find project!");
          }
        } catch (error) {
          setMessage("Project plan could not be loaded!");
        }
      })
      .catch((error) => {
        console.log("error: ", error);
        setMessage("Project plan could not be loaded!");
      });
  }, [unique_title]);

  return (
    <Fragment>
      <div className="container">
        {project.id ? (
          <Fragment>
            <div className="col-lg-12 m-4 p-4 text-center">
              <h2>{project.title}</h2>
              <h4>Milestones</h4>
              <Link className="btn btn-light" to="/">Back to projects</Link>
            </div>
            <MileStones milestones={project.milestones} users={project.users} />
          </Fragment>
        ) : (
          <Fragment>
            <div className="col-lg-12 m-4 p-4 text-center">
              <h4>{message}</h4>
              <Link to="/">Back to main page</Link>
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default withRouter(Project);
