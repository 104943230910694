import React from "react";
import { Fragment } from "react";
import { Route } from "react-router-dom";
import "./assets/scss/main.scss";
import Home from "./components/Home";
import Project from "./components/Project";

function App() {
  return (
    <Fragment>
      <Route>
        <Route exact path="/" component={Home} />
        <Route exact path="/:unique_title" component={Project} />
      </Route>
    </Fragment>
  );
}

export default App;
