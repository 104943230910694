import React, { Fragment, useState } from "react";
import Moment from "moment";

import { MilestoneTask } from "./MilestoneTask";
import { Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import MilestoneForm from "./forms/MilestoneForm";
import Axios from "axios";
import { appConfig } from "../appConfig";

export const MileStone = (props) => {
  const [milestone, setMilestone] = useState(props.milestone);
  const [show, setShow] = useState(false);
  const [formErrors, setFormErrors] = useState(null);
  const [formData, setFormData] = useState({
    status_code: props.milestone.status_code,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleClose = () => {
    setFormErrors(null);
    setShow(false);
  };
  const handleShow = () => {
    setFormErrors(null);
    setShow(true);
  };

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    Axios.post(
      `${appConfig.apiRootUrl}/project-plan/update-milestone/${milestone.id}`,
      formData
    )
      .then((res) => {
        setIsSubmitting(false);
        setMilestone(res.data.milestone);
        // setTasks(res.data.milestone.tasks);
        // setMilestone((prevState) => {
        //   return({
        //     ...prevState,
        //     ...res.data.milestone,
        //     tasks: res.data.milestone.tasks
        //   });
        // });
        setShow(false);
        // console.log("res: ", res);
        // try {
        //   if (Object.keys(res.data.data.projects).length > 0) {
        //     setProjects(res.data.data.projects);
        //   } else {
        //     setMessage("All done! No projects at present");
        //   }
        // } catch (error) {
        //   setMessage("Project(s) could not be loaded!");
        // }
      })
      .catch((error) => {
        setIsSubmitting(false);
        if (error.response) {
          let errors = [];

          if (error.response.data.error instanceof Array) {
            error = "";
            for (var i in error.response.data.error) {
              errors = errors + error.response.data.error[i][0];
            }
          } else if (error.response.data.error instanceof Object) {
            for (let [value] of Object.entries(
              error.response.data.error
            )) {
              // console.log(key, value);
              errors.push(value);
            }
          } else if (error.response.data.error) {
            errors.push(error.response.data.error);
          }
          setFormErrors(errors);
        } else if (error.request) {
          setFormErrors([error.request]);
        } else {
          setFormErrors([error.message]);
        }
      });
  };

  const getStatusData = (statusCode) => {
    let statusData = {
      milestoneStatus: "not-started",
      milestoneBtnClass: "btn-light",
      milestoneStatusIcon: "far fa-hourglass",
      milestoneTitleIcon: "far fa-hourglass",
    };
    switch (statusCode) {
      case "CO":
        statusData = {
          milestoneStatus: "completed",
          milestoneBtnClass: "btn-success",
          milestoneStatusIcon: "fas fa-thumbs-up",
          milestoneTitleIcon: "fas fa-check",
        };
        break;
      case "IP":
        statusData = {
          milestoneStatus: "in-progress",
          milestoneBtnClass: "btn-warning",
          milestoneStatusIcon: "fas fa-hourglass-half",
          milestoneTitleIcon: "far fa-clock",
        };
        break;
      case "NS":
      default:
        statusData = {
          milestoneStatus: "not-started",
          milestoneBtnClass: "btn-light",
          milestoneStatusIcon: "far fa-hourglass",
          milestoneTitleIcon: "far fa-hourglass",
        };
        break;
    }
    return statusData;
  };

  return (
    <Fragment>
      <div
        className={
          "timeline " + getStatusData(milestone.status_code).milestoneStatus
        }
      >
        <div className="timeline-content">
          <div className="circle">
            <span>
              <i
                className={
                  "main-icon " +
                  getStatusData(milestone.status_code).milestoneStatusIcon
                }
              ></i>
            </span>
          </div>
          <div className="content">
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="tooltip-top">{milestone.status}</Tooltip>}
            >
              <span onClick={handleShow} className="year btn btn-danger">
                {milestone.title}
              </span>
            </OverlayTrigger>{" "}
            {milestone.description}
            <Modal
              show={show}
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
              size="lg"
              centered
            >
              <form
                className="form-horizontal col-md-12"
                onSubmit={handleSubmit}
              >
                <Modal.Header closeButton>
                  <Modal.Title>{milestone.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Fragment>
                    {formErrors ? (
                      <div className="alert alert-danger">
                        <ul className="p-0 m-0 list-unstyled">
                          {formErrors.map((error, index) => (
                            <li key={index}>{error}</li>
                          ))}
                        </ul>
                      </div>
                    ) : (
                      ""
                    )}
                    <MilestoneForm
                      formData={formData}
                      milestone={milestone}
                      users={props.users}
                      handleChange={handleChange}
                    />
                  </Fragment>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Submitting..." : "Submit"}
                  </Button>
                </Modal.Footer>
              </form>
            </Modal>
            <h4 className="title">
              <i
                className={
                  "main-icon " +
                  getStatusData(milestone.status_code).milestoneTitleIcon
                }
              ></i>{" "}
              {Moment(milestone.due_date).format("ddd DD MMM YYYY")}
            </h4>
            <div className="description">
              <ul className="task-list">
                {milestone.tasks.map((task) => (
                  <MilestoneTask key={task.id} task={task} users={props.users} />
                ))}
              </ul>
            </div>
            <div className="icon">
              <span></span>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
