export let appConfig = {
  environment: "dev",
  version: "1.0.0",
  mainWebsiteUrl: "http://localhost:9331",
  adminPanelUrl: "http://192.168.1.231:8000",
  apiRootUrl: "http://192.168.1.231:8000/api",
};

const hostname = window && window.location && window.location.hostname;

if (hostname === "projects.tracooo.com" || hostname === "www.projects.tracooo.com") {
  appConfig = Object.assign({}, appConfig, {
    mainWebsiteUrl: "https://tracooo.com",
    adminPanelUrl: "https://admin.tracooo.com",
    apiRootUrl: "https://api.tracooo.com/api",
  });
} else {
  appConfig = Object.assign({}, appConfig, {});
}
