import Axios from "axios";
import React, { Fragment } from "react";
import { useState } from "react";
import { Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { appConfig } from "../appConfig";
import TaskForm from "./forms/TaskForm";

export const MilestoneTask = (props) => {
  const [task, setTask] = useState(props.task);
  const [show, setShow] = useState(false);
  const [formErrors, setFormErrors] = useState(null);
  const [formData, setFormData] = useState({
    status_code: props.task.status_code,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleClose = () => {
    setFormErrors(null);
    setShow(false);
  };
  const handleShow = () => {
    setFormErrors(null);
    setShow(true);
  };

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    Axios.post(
      `${appConfig.apiRootUrl}/project-plan/update-task/${task.id}`,
      formData
    )
      .then((res) => {
        setIsSubmitting(false);
        setTask(res.data.task);
        setShow(false);
        console.log("res: ", res);
        // try {
        //   if (Object.keys(res.data.data.projects).length > 0) {
        //     setProjects(res.data.data.projects);
        //   } else {
        //     setMessage("All done! No projects at present");
        //   }
        // } catch (error) {
        //   setMessage("Project(s) could not be loaded!");
        // }
      })
      .catch((error) => {
        setIsSubmitting(false);
        if (error.response) {
          let errors = [];

          if (error.response.data.error instanceof Array) {
            error = "";
            for (var i in error.response.data.error) {
              errors = errors + error.response.data.error[i][0];
            }
          } else if (error.response.data.error instanceof Object) {
            for (let [value] of Object.entries(
              error.response.data.error
            )) {
              // console.log(key, value);
              errors.push(value);
            }
          } else if (error.response.data.error) {
            errors.push(error.response.data.error);
          }
          setFormErrors(errors);
        } else if (error.request) {
          setFormErrors([error.request]);
        } else {
          setFormErrors([error.message]);
        }
      });
  };

  const getStatusData = (statusCode) => {
    let statusData = {
      taskStatus: "not-started",
      taskBtnClass: "btn-light",
      taskStatusIcon: "far fa-hourglass",
      taskTitleIcon: "far fa-hourglass",
    };
    switch (statusCode) {
      case "CO":
        statusData = {
          taskStatus: "completed",
          taskBtnClass: "btn-success",
          taskStatusIcon: "fas fa-thumbs-up",
          taskTitleIcon: "fas fa-check",
        };
        break;
      case "IP":
        statusData = {
          taskStatus: "in-progress",
          taskBtnClass: "btn-warning",
          taskStatusIcon: "fas fa-hourglass-half",
          taskTitleIcon: "far fa-clock",
        };
        break;
      case "NS":
      default:
        statusData = {
          taskStatus: "not-started",
          taskBtnClass: "btn-light",
          taskStatusIcon: "far fa-hourglass",
          taskTitleIcon: "far fa-hourglass",
        };
        break;
    }
    return statusData;
  };

  return (
    <Fragment>
      <li className={getStatusData(task.status_code).taskStatus}>
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="tooltip-top">
            {task.status}
            <br />
            <small>{task.assignee}</small>
            </Tooltip>}
        >
          <Button
            variant="primary"
            size="sm"
            onClick={handleShow}
            className={getStatusData(task.status_code).taskBtnClass}
          >
            <i className={getStatusData(task.status_code).taskStatusIcon}></i>
          </Button>
        </OverlayTrigger>{" "}
        {task.description}
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          size="lg"
          centered
        >
          <form className="form-horizontal col-md-12" onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>{task.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Fragment>
                {formErrors ? (
                  <div className="alert alert-danger">
                    <ul className="p-0 m-0 list-unstyled">
                      {formErrors.map((error, index) => (
                        <li key={index}>{error}</li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  ""
                )}
                <TaskForm
                  formData={formData}
                  task={task}
                  users={props.users}
                  handleChange={handleChange}
                />
              </Fragment>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" type="submit" disabled={isSubmitting}>
                {isSubmitting ? "Submitting..." : "Submit"}
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      </li>
    </Fragment>
  );
};
